@import './variables.scss';
@import './mixin.scss';

.create-shipment-container {
    .navigation-contain {
        @include flexItemsCenter;
        gap: 16px;
        margin-bottom: 24px;

        .back-btn {
            width: 40px;
            height: 40px;
            background-color: #FFF;
            border-radius: 100%;
            border: 1px solid $grey_200;
            box-shadow: 0px 10px 18px -2px #10192812;
            @include flexCenter;
            padding: 0;
            color: $grey_500;
            font-weight: 700;
            font-size: 14px;
        }

        .nav-options {
            @include flexItemsCenter;
            gap: 12px;
            color: $grey_400;
            font-weight: 500;
            list-style-type: none;
            padding: 0;
            margin-bottom: 0;
            font-size: 14px;

            &-arrow {
                font-size: 10px;
                color: $grey_300;
                font-weight: 600;
            }

            &-active {
                color: $grey_600;
            }
        }
    }

    .create-shipment-wrapper {
        background-color: #FFF;
        padding: 24px 20px;
        border-radius: 24px;

        .create-shipment-title {
            @include flexItemsCenter;
            gap: 8px;
            font-size: 18px;
            // color: $grey_800;
            font-weight: 700;
            margin-bottom: 24px;
        }

        .create-shipment-tab {
            @include flexItemsCenter;
            margin-bottom: 20px;
            list-style: none;
            padding: 0;

            &-option {
                @include flexItemsCenter;
                gap: 8px;
                padding: 10px 16px;
                font-size: 16px;
                font-weight: 500;
                border: 1px solid $grey_200;
                cursor: pointer;
                transition: all 500ms ease-in-out;

                &:hover {
                    background-color: #D6F9E4;
                    color: $color_green;
                }

                &:first-child {
                    border-radius: 8px 0px 0px 8px;
                }

                &:last-child {
                    border-radius: 0px 8px 8px 0px;
                }

                &-active {
                    border-color: #93D8BA;
                    background-color: #D6F9E4;
                    color: $color_green;
                }
            }
        }
        .shipment-form-contain {
            background-color: $grey_75;
            border: 1px solid $grey_200;
            border-radius: 24px;
            padding: 32px 20px;

            .create-shipment-form-tabs {
                list-style: none;
                @include flexItemsCenter;
                padding: 0;
                margin-bottom: 40px;

                &-option {
                    @include flexItemsCenter;
                    padding: 10px 12px;
                    gap: 8px;
                    border: 1px solid $grey_300;
                    border-radius: 6px;
                    font-size: 16px;
                    font-weight: 500;
                    color: $grey_500;

                    &-active {
                        color: $color_green;
                        border-color: #62C79A;
                        background-color: #E8F7F0;
                    }
                }

                &-arrow {
                    padding: 8px;
                    font-size: 14px;
                    color: $grey_300;

                    &-active {
                        color: #93D8BA;
                    }
                }
            }

            .create-shipment-grid {
                display: grid;
                grid-template-columns: repeat(3, minmax(0, 1fr));
                gap: 40px;

                .create-shipment-info {
                    
                    img {
                        margin-top: 4px;
                    }
                    
                    display: flex;
                    align-items: flex-start;
                    gap: 12px;

                    &-top {
                        margin-bottom: 40px;
                    }

                    &-title {
                        font-size: 16px;
                        font-weight: 700;
                        color: $grey_900;
                        margin-bottom: 6px;
                    }

                    &-sub {
                        font-size: 14px;
                        font-weight: 500;
                    }
                }

                .service-card-wrap {
                    .service-card {
                        border: 1px solid $grey_200;
                        border-radius: 10px;
                        padding: 12px;
                        cursor: pointer;
                        position: relative;

                        &:hover {
                            background-color: #d5f3e5;
                        }

                        .service-check {
                            position: absolute;
                            right: 12px;
                            top: 12px;
                            color: $color_green;
                        }
                    }
                }

                .create-shipment-info-form {
                    &-row {
                        row-gap: 20px;
                        margin-bottom: 40px;

                        &-input {
                            label: {
                                font-size: 16px;
                                font-weight: 500;
                                color: $grey_500;
                                margin-bottom: 12px;
                            }
                        }
                    }

                    &-btn, &-btn-secondary {
                        padding: 12px 24px;
                        border-radius: 8px;
                        background: linear-gradient(180deg, #44BC86 0%, #15AB68 66.67%, #139C5F 96.87%);
                        font-size: 14px;
                        font-weight: 700;
                        color: #FFF;
                        border: none;

                        &:disabled {
                            background: $grey_400;
                            opacity: 0.6;
                        }
                    }

                    &-btn-secondary {
                        background: #FFF;
                        color: #139C5F;
                        border: 1px solid #139C5F;
                    }
                }

                .create-shipment-info-form {
                    grid-column: span 2 / span 2;
                }

                .carriers-list {
                    list-style: none;

                    &-option {
                        padding: 12px 0;
                        border-bottom: 1px solid $grey_200;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        &-first-row {
                            @include flexItemsCenter;
                            gap: 24px;
                        }

                        &-logo {
                            width: 60px;
                            height: 60px;
                            border-radius: 10px;
                            background-color: $grey_100;

                            img {
                                width: 60px;
                                height: 60px;
                                border-radius: 10px;
                            }
                        }

                        &-carrier-name {
                            font-weight: 700;
                            font-size: 14px;
                            color: $grey_700;
                            margin: 0;
                        }

                        &-transit {
                            font-size: 12px;
                            margin: 0;
                            color: $grey_400;
                            font-weight: 500;
                        }

                        &-transit-time {
                            color: $grey_500;
                        }

                        &-price-label {
                            font-weight: 500;
                            color: $grey_400;
                            font-size: 12px;
                            margin-bottom: 12px;
                        }

                        &-price {
                            font-size: 14px;
                            font-weight: 700;
                            color: $grey_700;
                            margin: 0;
                        }

                    }
                }

                .confirm-details-wrapper {
                    background-color: #FFF;
                    border-radius: 16px;
                    padding: 24px 16px;

                    .confirm-details {
                        &-title {
                            font-size: 16px;
                            color: $grey_800;
                            font-weight: 700;
                            margin-bottom: 12px;
                        }

                        &-address {
                            position: relative;
                            display: flex;
                            flex-direction: column;
                            row-gap: 4px;
                            padding: 12px 0;
                            border-top: 1px solid $grey_200;
                            border-bottom: 1px solid $grey_200;

                            &-option {
                                padding: 24px 20px;
                                border-radius: 14px;
                                background-color: $grey_75;
                                width: 100%;
                                display: flex;
                                align-items: flex-start;
                                justify-content: space-between;

                                &-text-1 {
                                    font-size: 14px;
                                    color: $grey_500;
                                }

                                &-text-2 {
                                    font-size: 16px;
                                    font-weight: 500;
                                    color: $grey_700;
                                    margin-bottom: 4px;
                                }
                            }

                            &-icon {
                                position: absolute;
                                left: 50%;
                                top: 40%;
                                width: 48px;
                                height: 48px;
                                border-radius: 100%;
                                background-color: #FFF;
                                @include flexCenter;
                                font-size: 20px;
                            }
                        }

                        .confirm-details-payment {
                            display: flex;
                            width: 100%;
                            gap: 12px;
                            margin-top: 12px;

                            &-option {
                                background-color: $grey_75;
                                border-radius: 14px;
                                padding: 20px;
                                width: 100%;

                                &-text-1 {
                                    font-size: 14px;
                                    color: $grey_500;
                                    margin-bottom: 4px;
                                }

                                &-text-2 {
                                    font-weight: 500;
                                    color: $grey_700;
                                }
                            }
                        }

                        .confirm-summary {
                            margin-top: 24px;

                            &-title {
                                font-weight: 700;
                                color: $grey_800;
                                margin-bottom: 12px;
                            }

                            &-cost {
                                border-top: 1px solid $grey_200;
                                padding-top: 12px;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                padding-bottom: 12px;
                                margin-bottom: 8px;

                                &-text-1 {
                                    font-weight: 500;
                                    font-size: 14px;
                                    color: $grey_500;
                                }

                                &-text-2 {
                                    font-weight: 500;
                                    color: $grey_700;
                                    margin: 0;
                                }

                                &-text-3 {
                                    font-size: 14px;
                                    color: $grey_500;
                                }
                            }

                            &-term {
                                display: flex;
                                gap: 8px;
                                align-items: flex-start;
                                padding-bottom: 8px;
                                margin-bottom: 16px;

                                p {
                                    margin: 0;
                                    padding: 0;
                                    line-height: 16px;
                                    font-size: 14px;
                                    font-weight: 500;
                                }
                            }
                        }

                        &-packages {
                            padding: 12px 0;
                            border-top: 1px solid $grey_200;
                            border-bottom: 1px solid $grey_200;

                            &-package {
                                border: 1px solid $grey_100;
                                background-color: $grey_50;
                                border-radius: 12px;
                                padding: 12px 16px;
                                cursor: pointer;

                                &-info {
                                    @include flexItemsCenter;
                                    justify-content: space-between;

                                    &-wrap {
                                        @include flexItemsCenter;
                                        gap: 12px;
                                    }

                                    &-title {
                                        font-weight: 700;
                                        font-size: 14px;
                                        color: $grey_700;
                                        margin: 0;
                                    }

                                    &-values {
                                        font-size: 12px;
                                        color: $grey_500;
                                        margin: 0;
                                    }
                                }
                            }

                            &-items {
                                padding: 8px;
                                margin-top: 8px;

                                ul {
                                    list-style: none;
                                    padding: 0;

                                    li {
                                        display: flex;
                                        align-items: flex-end;
                                        justify-content: space-between;
                                        margin-bottom: 4px;
                                    }
                                }

                                &-label {
                                    font-size: 12px;
                                    color: $grey_400;
                                }

                                &-category {
                                    font-weight: 500;
                                    font-size: 14px;
                                    color: $grey_800;
                                }

                                &-quantity {
                                    font-size: 14px;
                                    color: $grey_500;
                                }
                            }
                        }
                    }
                }

                .packages-wrapper {
                    background-color: #FFF;
                    border-radius: 16px;
                    padding: 24px 16px;

                    &-top {
                        margin-bottom: 16px;

                        &-info {
                            @include flexItemsCenter;
                            justify-content: space-between;

                            &-title {
                                padding: 8px 12px;
                                border-radius: 40px;
                                border: 1px solid $grey_200;
                                @include flexCenter;
                                gap: 8px;
                                color: $grey_600;
                                font-weight: 500;
                                font-size: 12px;
                            }

                            &-btn-add {
                                border: 1.5px solid #0F794A;
                                border-radius: 8px;
                                background: transparent;
                                font-size: 14px;
                                font-weight: 700;
                                @include flexItemsCenter;
                                gap: 8px;
                                padding: 8px 16px;
                                color: $color_green;
                            }
                        }
                    }

                    &-packages {
                        display: flex;
                        border-bottom: 1px solid $grey_200;
                        list-style: none;
                        padding: 0;

                        &-package {
                            padding: 16px 40px 16px  12px;
                            font-weight: 500;
                            color: $grey_200;
                            font-size: 14px;
                            display: flex;
                            gap: 4px;
                            cursor: pointer;

                            &-active {
                                border-bottom: 1px solid #139C5F;
                                color: $color_green;
                            }
                        }
                    }

                    &-items {
                        padding-top: 16px;
                        padding-bottom: 16px;
                        list-style: none;

                        &-item {
                            padding: 12px 0;
                            border-bottom: 1px solid $grey_200;

                            &-grid {
                                display: grid;
                                grid-template-columns: repeat(5, minmax(0, 1fr));
                                align-items: flex-start;
                            }
                        }

                        &-title {
                            display: flex;
                            align-items: flex-start;
                            grid-column: span 3 / span 3;

                            &-initials {
                                width: 45px;
                                height: 45px;
                                border-radius: 100%;
                                font: 600;
                                @include flexCenter;
                                color: $grey_800;
                            }

                            &-category {
                                margin-bottom: 4px;
                                font-weight: 600;
                                color: $grey_800;
                            }

                            &-description {
                                font-size: 14px;
                                color: $grey_400;
                            }
                        }

                        &-quantity-label {
                            font-size: 12px;
                            font-weight: 700;
                            color: $grey_400;
                            margin-bottom: 4px;
                        }

                        &-quantity {
                            font-weight: 700;
                            color: $grey_800;
                        }

                        &-btn-wrap {
                            @include flexCenter;
                        }

                        &-btn-edit {
                            background: transparent;
                            padding: 14px 17px;
                            border-radius: 100%;
                            border: none;
                            font-size: 14px;
                            color: $grey_400;

                            &:hover {
                                background-color: #E3EFFC;
                                color: #1f64cc;
                            }
                        }

                        &-btn-delete {
                            background: transparent;
                            padding: 14px 17px;
                            border-radius: 100%;
                            border: none;
                            font-size: 14px;
                            color: $grey_400;

                            &:hover {
                                background-color: #FBEAE9;
                                color: $error_400;
                            }
                        }

                        &-add-item-wrap {
                            width: 100%;
                            padding-top: 20px;
                            padding-bottom: 20px;
                            display: flex;
                            justify-content: center;
                            border: 2px solid $grey_200;
                            border-radius: 17px;
                            transition: all 500ms ease-in-out;
                            cursor: pointer;
                            list-style: none;

                            &:hover {
                                background-color: $grey_100;
                            }
                        }

                        &-add-item-icon {
                            @include flexCenter;
                            column-gap: 8px;
                            margin-bottom: 10px;

                            &-text {
                                font-weight: 700;
                                color: $grey_500;
                                margin: 0;
                            }
                        }

                        &-add-item-msg {
                            font-size: 14px;
                            color: $grey_400;
                        }
                    }

                    &-dimensions {
                        margin-top: 32px;
                        padding-top: 8px;
                        padding-bottom: 8px;

                        &-title {
                            font-weight: 700;
                            color: $grey_800;
                            margin-bottom: 12px;

                            &-sub {
                                color: $grey_400;
                            }
                        }

                        &-form {
                            border-top: 1px solid $grey_200;
                            border-bottom: 1px solid $grey_200;
                            padding-top: 12px;
                            padding-bottom: 12px;
                            margin-bottom: 12px;
                        }

                        &-label {
                            font-size: 14px;
                            color: $grey_500;
                            font-weight: 500;
                            margin-bottom: 12px;

                            &-sub {
                                color: $grey_400;
                            }
                        }
                    }

                    &-summary {
                        margin-top: 32px;
                        margin-bottom: 32px;
                        padding-top: 8px;
                        padding-bottom: 8px;

                        &-title {
                            font-weight: 700;
                            color: $grey_800;
                            margin-bottom:12px;
                        }

                        ul {
                            list-style: none;
                            padding: 12px 8px;
                            border-top: 1px solid $grey_200;

                            li {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                padding: 8px 0;
                            }
                        }

                        &-label {
                            font-size: 14px;
                            font-weight: 500;
                            color: $grey_500;
                            margin: 0;
                        }

                        &-value {
                            font-size: 14px;
                            font-weight: 500;
                            color: $grey_700;
                            margin: 0;
                        }

                        &-delete-btn {
                            border: 1px solid $error_700;
                            background-color: $error_50;
                            box-shadow: 0px 1px 3px 0px #0000001F
                                        ,0px 0.5px 0.5px 0px #00000040
                                        ,0px 2px 0.5px 0px #FBEAE94D inset
                                        ,0px -0.5px 0.5px 0px #E1564E inset;
                            border-radius: 8px;
                            padding: 8px 16px;
                            font-weight: 700;
                            font-size: 14px;
                            color: $error_400;
                            @include flexCenter;
                            gap: 8px;
                        }
                    }
                }
            }
        }
    }
}