@import './variables.scss';
@import './mixin.scss';

.icard {
    background-color: burlywood;
}

.page {
    padding-top: 20px;

    .icon {
        width: 55px;
        height: 55px;
    }

    .transporter-activity-container {
        border-bottom: 1.5px solid #EBEDEF;

        .recent_title {
            background-color: #FFF;
            border: 1px solid $grey_200;
            border-radius: 40px;
            @include flexItemsCenter;
            gap: 8px;
            padding: 8px 12px;

            p {
                font-size: 12px;
                color: $grey_600;
                margin: 0;
                font-weight: 500;
            }
        }

        .transporter-activity-body{
            max-height: 350px;
            min-height: 350px;
            overflow-y: scroll;
            background-color: #FFF;
            border-radius: 16px;
            padding: 12px;

            &:hover{
                overflow-y: scroll;
            }
            &::-webkit-scrollbar {
                background-color: transparent;
                width: 5px;
            }
            &::-webkit-scrollbar-track {
                background-color: var(--custom-white);
            }
            &::-webkit-scrollbar-thumb {
                background-color: #D4E6F1 ;
                border-radius: 20px;
                border: 1px solid var(--custom-white);
            }

            .transporter-activity-row {
                border-bottom: 1.5px solid #EBEDEF;
                padding-bottom: 10px;
                padding-top: 10px;

                .avatar {
                    min-width: 35px;
                    min-height: 35px;
                    max-width: 35px;
                    max-height: 35px;
                    border-radius: 50%;
                    margin-right: 10px;
                }

                .date-title {
                    font-size: 14px;
                }

                .body-text {
                    font-size: 14px;
                    color: #343a40;
                    line-height: 1.6 !important;
                }
            }
        }
    }

    .graph-select {
        margin-right: 20px;
        position: relative;

        & > select {
            font-size: 12px;
            height: 32px;
            border: 1px solid $grey_200;
            border-radius: 8px;
            background-color: transparent;
            padding: 0 32px 0 16px;
        }

        & svg {
            position: absolute;
            right: 12px;
            top: 12px;
            font-size: 10px;
        }
        
    }

    .recent-shipments-container {
        border-bottom: 1.5px solid #EBEDEF;

        a {
            text-decoration: none;
        }

        .recent_title {
            background-color: #FFF;
            border: 1px solid $grey_200;
            border-radius: 40px;
            @include flexItemsCenter;
            gap: 8px;
            padding: 8px 12px;

            p {
                font-size: 12px;
                color: $grey_600;
                margin: 0;
                font-weight: 500;
            }
        }

        .recent-shipment-body{
            max-height: 350px;
            min-height: 350px;
            overflow-y: scroll;
            background-color: #FFF;
            border-radius: 16px;
            padding: 12px;

            &:hover{
                overflow-y: scroll;
            }
            &::-webkit-scrollbar {
                background-color: transparent;
                width: 5px;
            }
            &::-webkit-scrollbar-track {
                background-color: var(--custom-white);
            }
            &::-webkit-scrollbar-thumb {
                background-color: #D4E6F1 ;
                border-radius: 20px;
                border: 1px solid var(--custom-white);
            }

            .recent-shipment-row {
                border-bottom: 1.5px solid #EBEDEF;
                padding-bottom: 10px;
                padding-top: 10px;

                .content {
                    width: 100%;
                }

                .avatar {
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    margin-right: 10px;
                }

                .name {
                    font-size: 14px;
                    color: #343a40;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 50%;
                }

                .destination {
                    font-size: 14px;
                    line-height: 1.6 !important;
                    color: #74788d;
                }

                .status{
                    padding: 3px 15px 3px 15px;
                    border-radius: 20px;
                    font-size: 12px;
                    border-width: 1px;
                    border-style: solid;
                }

                .Rejected, .Cancelled{
                    color: #924040;
                    background-color: #fde1e1;
                    border-color: #924040;
                }

                // .PendingApproval{
                //     color: #464855;
                //     background-color: #e3e4e8;
                //     border-color: #464855;
                // }

                .PendingPickup{
                    color: #37458b;
                    background-color: #dee3fa;
                    border-color: #ced5f8;
                }

                .Approved {
                    color: #0D5EBA;
                    background-color: #E3EFFC;
                    border-color: #B6D8FF;
                }

                .Delivered{
                    color: #1f7556;
                    background-color: #d6f3e9;
                    border-color: #1f7556;
                }

                .PickedUp,  .GoingToPickup, .GoingToDeliver, .PendingApproval{
                    color: #916c2e;
                    background-color: #fcf0db;
                    border-color: #916c2e;
                }
            }

            
        }
    }

    .recent-transactions-container {
        border-bottom: 1.5px solid #EBEDEF;

        .recent-transactions-body{
            max-height: 365px;
            min-height: 365px;
            overflow: scroll;

            &:hover{
                overflow-y: scroll;
            }
            &::-webkit-scrollbar {
                background-color: transparent;
                width: 5px;
            }
            &::-webkit-scrollbar-track {
                background-color: var(--custom-white);
            }
            &::-webkit-scrollbar-thumb {
                background-color: #D4E6F1 ;
                border-radius: 20px;
                border: 1px solid var(--custom-white);
            }

            .transaction-icon-credit {
                width: 28px;
                height: 28px;
                border-radius: 100%;
                background-color: $success_50;
                color: $success_400;
                @include flexCenter;
            }

            .transaction-icon-debit {
                width: 28px;
                height: 28px;
                border-radius: 100%;
                background-color: $error_50;
                color: $error_400;
                @include flexCenter;
            }

            .recent-transactions-table {
                width: 100%;

                tr {
                    border-bottom: 1.5px solid #EBEDEF;
                    padding-bottom: 10px;
                    padding-top: 10px;
                    width: 100%;
                }

                td:first-child {
                    //border-top: 1.5px solid #EBEDEF;
                }

                td.description {
                    width: 50%;
                    padding-bottom: 10px;
                    padding-top: 10px;

                    .icon {
                        width: 30px;
                        height: 30px;
                        margin-right: 10px;
                    }

                    .title {
                        font-size: 14px;
                        color: #343a40;
                    }
                }

                td.amount {
                    width: 25%;
                }

                td.status {
                    width: 25%;
                }
            }
        }

        .recent-transaction {
            border-bottom: 1.5px solid #EBEDEF;
            padding-bottom: 10px;
            padding-top: 10px;

            .icon {
                width: 30px;
                height: 30px;
                margin-right: 10px;
            }

            .title {
                font-size: 14px;
                color: #343a40;
                line-height: 1.6 !important;
            }

            .shipment-state {
                background-color: #F2D7D5;
                color: #A93226;
                padding: 0 5px 0 5px;
                border-radius: 3px;
                font-size: 13px;
            }

            .Pending {
                background-color: #FCF3CF;
                color: #f1b44c;
                padding: 0 5px 0 5px;
                border-radius: 3px;
                font-size: 13px;
                font-weight: bold;
            }

            .destination {
                font-size: 10px;
                line-height: 1.6 !important;
            }
        }

        
    }

    .top-cards {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        .card-item {
            border: 1px solid $grey_200;
            padding: 32px 0;
            gap: 32px;
            border-radius: 20px;
            background-color: $grey_75;
            display: flex;
            justify-content: center;

            .card-icon {
                width: 40px;
                height: 40px;
                border-radius: 100%;
                box-shadow: 0px 2.5px 5px -2.5px #B6E5D0;
                box-shadow: 0px 7.5px 10px -2.5px #68CDA040;
                border: 1.25px solid #B6E5D0;
                background: #E8F7F0;
                margin-bottom: 12px;
                @include flexCenter;
            }

            .card-title {
                color: $grey_500;
                font-size: 14px;
                font-weight: 500;
                text-align: center;
                margin-bottom: 4px;
            }

            .card-sub {
                color: $grey_400;
                font-size: 12px;
                text-align: center;
            }

            .card-data {
                color: $grey_800;
                font-size: 22px;
                // font-weight: 700;
            }
        }


    }

    .activities-contain {
        background-color: #FFF;
        padding: 20px;
        border-radius: 32px;
    }

    .grey-cards {
        background-color: $grey_75;
        border: 1px solid $grey_200;
        border-radius: 16px;
    }

    .graph-bg {
        border: 1px solid $grey_200;
        background-color: $grey_75;
    }
}