@import './mixin.scss';
@import './variables.scss';

.paginate-wrapper {
    @include flexCenter;
    width: fit-content;
    background-color: $grey_100;
    border-radius: 20px;
    padding: 4px;
    gap: 20px;

    &-btn {
        padding: 8px 16px;
        border-radius: 20px;
        width: fit-content;
        border: none;
        background-color: $grey_900;
        color: #FFF;
        font-weight: 500;
        font-size: 14px;
        box-shadow: 0px 10px 18px -2px #10192812;

        &:disabled {
            background-color: #FFF;
            border: 1px solid $grey_200;
            color: $grey_500;
        }
    }

    .paginate-bullet-btn-wrapper {
        @include flexCenter;
        gap: 3px;
        padding: 0;
        list-style: none;
        margin: 0;

        .paginate-bullet-btn {
            width: 33px;
            height: 33px;
            border-radius: 100%;
            @include flexCenter;
            font-size: 14px;
            border: none;
            background: transparent;
            color: $grey_400;
            font-weight: 500;

            &-active {
                background: #FFF;
                border: 1px solid $grey_200;
                box-shadow: 0px 10px 18px -2px #10192812;
                color: $grey_500;
            }
        }
    }
}