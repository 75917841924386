@import './mixin.scss';
@import './variables.scss';

.sidebar-wrap {
    min-height: 100vh;
    width: 250px;
    position: fixed;
    top: 0;
    bottom: 0;
    padding: 16px 0 16px 20px;
    z-index: 21;

    .sidebar {
        // overflow-y: scroll;
        background-color: $color_white;
        border-radius: 18px;
        width: 100%;
        min-height: 100%;
        border: 1px solid $grey_100;
    
        &::-webkit-scrollbar {
            width: 5px;
            height: 12px;
        }
    
        &::-webkit-scrollbar-track {
            background: white;
            border-radius: 10px;
        }
    
        &::-webkit-scrollbar-thumb {
            background: #ddd;
            visibility: hidden;
            border-radius: 10px;
        }
    
        &:hover::-webkit-scrollbar-thumb {
            visibility: visible;
        }
    
        hr {
            height: 0;
            border: 0.5px solid rgb(244, 244, 244);
        }
    
        .logo {
            height: 60px;
            background-color: white;
            padding: 5px;
            padding-left: 10px;
            z-index: 10;
            display: flex;
            width: 100%;
            border-bottom: 1.5px dashed $grey_200;
    
            .details {
                width: 100%;
                padding-left: 5px;
                border-radius: 5px;
    
                .operator-name {
                    margin: 0;
                    text-transform: uppercase;
                    color: $grey_900;
                    font-weight: 500;
                }
    
                .operator-id {
                    font-size: 13px;
                    margin-top: -3px;
                }
            }
        }
    
    }
}

.menu {

    max-height: 580px;
    overflow-y: hidden;
    padding-top: 20px;

    /* Customizing the scrollbar */
    &::-webkit-scrollbar {
        width: 8px; /* Slim scrollbar width */
    }
    
    /* Track styling */
    &::-webkit-scrollbar-track {
        background: #f1f1f1; /* Light grey track background */
    }
    
    /* Handle styling */
    &::-webkit-scrollbar-thumb {
        background-color: #888; /* Grey scrollbar handle */
        border-radius: 10px; /* Rounded handle for a cleaner look */
    }
    
    /* Handle hover effect */
    &::-webkit-scrollbar-thumb:hover {
        background-color: #555; /* Darker grey when hovered */
    }

    &:hover {
        overflow-y: auto;
    }

    @include for-tablet-down {    
        margin-top: 20px;
    }

    ul {
        
        list-style-type: none;
        padding: 4px;

        a {
            text-decoration: none;
            color: #74788d;
        }

        li {
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            align-items: center;
            padding: 16px;

            .icon {
                font-size: 18px;
                margin-right: 5px;
            }

            .chevron-arrow {
                float: right;
            }

            &:hover {
                background-color: #f3f8fb;
                color: #45B39D;
            }
        }
    }

    .menu-item {
        border-radius: 10px;
        &-active {
            background-color: #E8F7F0;
            color: $color_green;
        }
    }

    .menu-active {
        font-weight: 500;
        color: $grey_300;
    }

    .submenu-list {
        padding-left: 24px;

        transition: all ease-in-out 500ms;

        &-inactive {
            overflow: hidden;
            max-height: 0;
        }

        &-active {
            max-height: 1000px;
        }
    }

    li.submenu-item {
        cursor: pointer;
        font-size: 14px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-radius: 10px;
        gap: 8px;

        &:hover {
            background-color: #f3f8fb;
            color: #45B39D !important;
        }

        &.a:hover {
            color: #45B39D !important;
        }

        &-active {
            background-color: #E8F7F0;
            color: $color_green;
        }
    }
}

.company-banner {
    padding: 12px 0;
    border-top: 1.5px dashed $grey_200;
    width: 100%;
    @include flexCenter;
    gap: 12px;

    .text-01 {
        font-size: 10px;
        color: $grey_400;
        font-weight: 500;
        margin: 0;
    }

    .banner-section-2 {
        @include flexCenter;
        gap: 8px;

        .banner-logo {
            width: 24px;
            height: 24px;
            box-shadow: 0px 2px 4px -1px #10192805, 0px 5px 13px -5px #1019280D;
            border-radius: 100%;
            @include flexCenter;

            img {
                width: 16px;
                height: 16px;
            }
        }

        .text-02 {
            font-size: 12px;
            font-weight: 500;
            color: $color_green;
            margin: 0;
        }
    }
}