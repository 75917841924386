@import './variables.scss';
@import './mixin.scss';

.wallet-top-cards {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    .card-item {
        border: 1px solid $grey_200;
        padding: 32px 0;
        gap: 32px;
        border-radius: 20px;
        background-color: $grey_75;
        display: flex;
        justify-content: center;

        .card-icon {
            width: 40px;
            height: 40px;
            border-radius: 100%;
            box-shadow: 0px 2.5px 5px -2.5px #B6E5D0;
            box-shadow: 0px 7.5px 10px -2.5px #68CDA040;
            border: 1.25px solid #B6E5D0;
            background: #E8F7F0;
            margin-bottom: 12px;
            @include flexCenter;
        }

        .card-title {
            color: $grey_500;
            font-size: 14px;
            font-weight: 500;
            text-align: center;
            margin-bottom: 4px;
        }

        .card-sub {
            color: $grey_400;
            font-size: 12px;
            text-align: center;
        }

        .card-data {
            color: $grey_800;
            font-size: 22px;
            // font-weight: 700;
        }
    }
}

.virtual-card-contain {
    border: 1px solid $grey_200;
    background-color: $grey_75;
    border-radius: 20px;
    padding: 20px;
    height: 100%;
    display: flex;
    justify-content: center;
}

.available-balance {
    font-size: 14px;
    text-align: center;

    &-fee {
        font-size: 32px;
        font-size: 500;
        padding-left: 12px;
    }
}

.virtual-card {
    background: linear-gradient(135deg, #66BB6A, #0F973D);
    padding: 16px;
    width: 420px;
    border-radius: 20px;
    position: relative;
    overflow: hidden;

    .virtual-card-pattern {
        position: absolute;
        left: 0;
        top: 0;
        width: 500px;
        height: 500px;
        mix-blend-mode: overlay;
        opacity: 30%;
    }

    .bg-dark-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #00000090;
        mix-blend-mode: overlay;
    }

    .account-copy {
        background-color: #FFF;
        border-radius: 10px;
        padding: 6px 14px;
        display: flex;
        align-items: center;
        gap: 4px;
        color: $grey_600;
        font-size: 12px;
        cursor: pointer;
    }

    .virtual-info {
        position: relative;
        z-index: 3;

        p {
            color: #FFFFFF;
            margin-bottom: 2px;
            font-weight: 600;
        }
    }

}