@import './variables.scss';
@import './mixin.scss';

.dropzone-div {
  text-align: center;
  padding: 35px 20px 20px 20px;
  border: 2px #0095ff dashed;
  width: 60%;
  margin: auto;
  height: 100px;
}

.options-card {
  background-color: #FFF;
  border-radius: 16px;

  label {
    font-size: 12px;
    font-weight: 500;
    color: $grey_800;
  }
}