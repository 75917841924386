@import './mixin.scss';
@import './variables.scss';

.estimate-wrap {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 40px;
    background-color: $grey_75;
    border: 1px solid $grey_200;
    border-radius: 24px;
    padding: 32px 20px;

    &-form {
        grid-column: span 2 / span 2;
    }
}

.navigation-contain {
    @include flexItemsCenter;
    gap: 16px;
    margin-bottom: 24px;

    .back-btn {
        width: 40px;
        height: 40px;
        background-color: #FFF;
        border-radius: 100%;
        border: 1px solid $grey_200;
        box-shadow: 0px 10px 18px -2px #10192812;
        @include flexCenter;
        padding: 0;
        color: $grey_500;
        font-weight: 700;
        font-size: 14px;
    }

    .nav-options {
        @include flexItemsCenter;
        gap: 12px;
        color: $grey_400;
        font-weight: 500;
        list-style-type: none;
        padding: 0;
        margin-bottom: 0;
        font-size: 14px;

        &-arrow {
            font-size: 10px;
            color: $grey_300;
            font-weight: 600;
        }

        &-active {
            color: $grey_600;
        }
    }
}

.rates-toggle-bar {
    width: 75%;
    position: relative;
    border: 1px solid $grey_200;
    padding: 14px 12px;
    border-radius: 24px;
    @include flexCenter;
    cursor: pointer;

    .rate-toggle-switch {
        position: absolute;
        width: 50%;
        height: 84%;
        left: 4px;
        top: 4px;
        border-radius: 24px;
        z-index: 0;
        background-color: $color_green;
        transition: all 500ms ease-in-out;
    }

    .rate-toggle-switch-state {
        transform: translateX(97%);
    }

    .toggle-option {
        width: 100%;
        display: flex;
        justify-content: center;
        background: transparent;
        position: relative;
        z-index: 2;

        &-active {
            color: #FFF;
        }
    }
}

.estimate-text {
    font-weight: 700;
    color: $grey_400;
    margin-bottom: 16px;
}

.calculate-estimate-form {
    row-gap: 16px;

    label {
        font-weight: 500;
        color: $grey_500;
        margin-bottom: 12px;
    }
}

.estimate-info {
    img {
        margin-top: 4px;
    }
    
    display: flex;
    align-items: flex-start;
    gap: 12px;

    &-top {
        margin-bottom: 40px;
    }

    &-title {
        font-size: 16px;
        font-weight: 700;
        color: $grey_900;
        margin-bottom: 6px;
    }

    &-sub {
        font-size: 14px;
        font-weight: 500;
    }
}

.rates-result-wrap {
    background-color: #FFF;
    border-radius: 16px;
    padding: 24px 16px;

    .rates-result-carriers {
        .carriers-list {
            list-style: none;

            &-option {
                padding: 12px 0;
                border-bottom: 1px solid $grey_200;
                display: flex;
                align-items: center;
                justify-content: space-between;

                &-first-row {
                    @include flexItemsCenter;
                    gap: 24px;
                }

                &-logo {
                    img {
                        width: 60px;
                        height: 60px;
                        border-radius: 10px;
                    }
                }

                &-carrier-name {
                    font-weight: 700;
                    font-size: 14px;
                    color: $grey_700;
                    margin: 0;
                }

                &-transit {
                    font-size: 12px;
                    margin: 0;
                    color: $grey_400;
                    font-weight: 500;
                }

                &-transit-time {
                    color: $grey_500;
                }

                &-price-label {
                    font-weight: 500;
                    color: $grey_400;
                    font-size: 12px;
                    margin-bottom: 12px;
                }

                &-price {
                    font-size: 14px;
                    font-weight: 700;
                    color: $grey_700;
                    margin: 0;
                }

            }
        }
    }
}