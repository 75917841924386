.Activated{
  color: #1f7556;
  background-color: #d6f3e9;
  border-color: #c2eddd;
  padding: 5px 15px 5px 15px;
  border-radius: 20px;
  font-size: 14px;
}

.NotActivated{
  color: #916c2e;
  background-color: #fcf0db;
  border-color: #fbe9c9;
  padding: 5px 15px 5px 15px;
  border-radius: 20px;
  font-size: 14px;
}