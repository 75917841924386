@import './variables.scss';
@import './mixin.scss';

.user-menu-container{
  position: relative;
}

.user-menu {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 200px;
  top: 50px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 80;
}

.user-menu-visible {
  display: block;
}

/* Links inside the dropdown */
.user-menu span {
  float: none;
  cursor: pointer;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

/* Add a grey background color to dropdown links on hover */
.user-menu span:hover {
  background-color: #ddd;
}

.avatar:focus + .user-menu{
  display: block;
}

.Rejected, .Cancelled{
  color: #924040;
  background-color: #fde1e1;
  border-color: #fcd2d2;
  padding: 5px 15px 5px 15px;
  border-radius: 20px;
  font-size: 14px;
}

.PendingApproval{
  color: #464855;
  background-color: #e3e4e8;
  border-color: #d5d7dd;
  padding: 5px 15px 5px 15px;
  border-radius: 20px;
  font-size: 14px;
}

.PendingPickup{
  color: #37458b;
  background-color: #dee3fa;
  border-color: #ced5f8;
  padding: 5px 15px 5px 15px;
  border-radius: 20px;
  font-size: 14px;
}

.Delivered{
  color: #1f7556;
  background-color: #d6f3e9;
  border-color: #c2eddd;
  padding: 5px 15px 5px 15px;
  border-radius: 20px;
  font-size: 14px;
}

.PickedUp,  .GoingToPickup, .GoingToDeliver{
  color: #916c2e;
  background-color: #fcf0db;
  border-color: #fbe9c9;
  padding: 5px 15px 5px 15px;
  border-radius: 20px;
  font-size: 14px;
}

.shipment-class{
  width: 30px;
  height: 30px;
  padding: 3px;
  background-color: #D4E6F1;
  color: #2471A3;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.account-class{
  width: 40px;
  height: 40px;
  background-color: #FDEBD0;
  color: #E67E22;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.admin-activities-container{
  min-height: 650px;
  max-height: 650px;
  overflow-y: hidden;
}

.admin-wrapper {
  background-color: $grey_75;
  border: 1px solid $grey_200;
  border-radius: 24px;
  padding: 32px 20px;
}

.admin-type {
  padding: 6px 12px;
  border-radius: 22px;
  font-size: 12px;
  font-weight: 500;
  @include flexCenter;
  gap: 8px;
  width: fit-content;

  &-admin {
      background-color: #D6F9E4;
      border: 1px solid #0F794A;
      color: $color_green;
  }

  &-super-admin {
      background-color: $secondary_50;
      border: 1px solid $secondary_400;
      color: $secondary_600;
  }
}

.admin-name {
  font-weight: 700;
  color: $grey_900;
  margin-top: 6px;
  margin-bottom: 0;
}

.admin-email {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.admin-label {
  font-weight: 500;
  color: $grey_400;
  margin-bottom: 0px;
}

.admin-value {
  font-weight: 500;
  color: $grey_600;
  margin-bottom: 16px;
}

.single-admin-tab {
  display: flex;
}

.admin-tab {
  padding: 6px 16px;
  display: flex;
  gap: 8px;
  align-items: center;
  font-weight: 500;
  color: $grey_400;
  font-size: 15px;
  border: 1px solid $grey_200;
  cursor: pointer;

  &-left {
    border-radius: 8px 0px 0px 8px;

    &-admin {
      border-radius: 8px;
    }
  }

  &-right {
    border-radius: 0px 8px 8px 0px;
  }

  &-active {
    background-color: #D6F9E4;
    border: 1px solid #93D8BA;
    color: $color_green;
  }
}

.privileges-card {
  border: 1px solid $grey_200;
  padding: 24px 16px;
  border-radius: 16px;
  background-color: $grey_75;

  .top-banner {
    @include flexItemsCenter;
    padding: 8px 12px;
    background-color: #FFF;
    gap: 8px;
    border-radius: 40px;
    width: fit-content;
    border: 1px solid $grey_200;
    margin-bottom: 16px;

    p {
      font-size: 12px;
      color: $grey-600;
    }
  }

  .options-card {
    background-color: #FFF;
    border-radius: 16px;

    label {
      font-size: 12px;
      font-weight: 500;
      color: $grey_800;
    }
  }
}