
.single-notification-row{
  min-width: 600px;
  max-width: 600px;
  margin-bottom: 15px;
  cursor: pointer;

  .notification-icon{
    height: 35px;
    width: 35px;
    margin-right: 10px;
  }

  .single-notification-body{
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
  }

  .shipment-class{
    width: 40px;
    height: 40px;
    background-color: #D4E6F1;
    color: #2471A3;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }

  .account-class{
    width: 40px;
    height: 40px;
    background-color: #FDEBD0;
    color: #E67E22;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }

  .user-class{
    width: 40px;
    height: 40px;
    background-color: #D4EFDF;
    color: #27AE60;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }

  .icon{
    width: 25px;
    height: 25px;
  }
}