@import './mixin.scss';
@import './variables.scss';

th, td {
  border-bottom: 1px dashed #BFC9CA;
  border-collapse: collapse;
}

.reports-home {
  .report-shipment-card {
    background: url("../assets/reports/ShipmentsCard.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .report-customer-card {
    background: url("../assets/reports/CustomersCard.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .report-transporter-card {
    background: url("../assets/reports/TransporterCard.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .report-card {
    padding: 32px 24px;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: -1px 0 0 0 #E4E7EC;
    cursor: pointer;

    .card-icon {
      @include flexCenter;
      border: 1.56px solid #B6E5D0;
      border-radius: 100%;
      background: #E8F7F0;
      width: 48px;
      height: 48px;
      margin-bottom: 12px;
      box-shadow: 0px 3.12px 6.25px -3.12px #B6E5D0, 0px 9.37px 12.5px -3.12px #68CDA040;
      z-index: 2;
    }

    .report-card-title {
      z-index: 2;
      font-weight: 500;
      font-size: 14px;
      color: $grey_500;
      margin-bottom: 5px;
      z-index: 3 !important;
      position: relative;
    }

    .card-illustrate {
      height: 100%;
      z-index: 1;
    }

    .report-card-desc {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      z-index: 3 !important;
      position: relative;
    }
  }

  .report-shipment-card:hover {
    border: 2px solid #FA9874;
  }

  .report-customer-card:hover{
    border: 2px solid #93D8A5;
  }

  .report-transporter-card:hover{
    border: 2px solid #F7C164;
  }
}

.input-query {
  width: 200px;

  @include for-tablet-down {    
    width: 100%;
  }
}

.table-contain {

  @include for-tablet-down {    
      max-width: 100vw;
      overflow: scroll;
  }
}

th, td {
  padding: 15px 10px 15px 10px;
}

.sales-wrap {
  background-color: $grey_100;
  border-radius: 16px;
  padding: 12px;
  border: 1px solid $grey_200;

  &-inner {
    background-color: $color_white;
    border-radius: 16px;
  }
}