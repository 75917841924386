@import './variables.scss';
@import './mixin.scss';

.transporters {
    .transporter-card {
        background-color: $grey_75;
        border: 1px solid $grey_200;
        border-radius: 15px;
        width: 100%;
        padding: 24px 20px;
        transition: transform 500ms ease-in-out;

        &:hover {
            transform: scale(102%);
        }
    }

    .transporter-type {
        padding: 6px 12px;
        border-radius: 22px;
        font-size: 12px;
        font-weight: 500;
        @include flexCenter;
        gap: 8px;

        &-transporter {
            background-color: #D6F9E4;
            border: 1px solid #0F794A;
            color: $color_green;
        }

        &-super-transporter {
            background-color: $secondary_50;
            border: 1px solid $secondary_400;
            color: $secondary_600;
        }
    }

    .transporter-name {
        font-weight: 500;
        color: $grey_900;
        margin-top: 10px;
        text-align: center;
    }

    .transporter-email {
        font-size: 12px;
        font-weight: 500;
        text-align: center;
    }
}