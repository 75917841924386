@import './mixin.scss';
@import './variables.scss';

.shipments-container {
    
    .shipment-top-input {
        width: 230px;

        @include for-tablet-down {    
            width: 100%;
        }
    }

    .table-contain {

        @include for-tablet-down {    
            max-width: 100vw;
            overflow: scroll;
        }
    }

    .shipments-table{

        th, td {
            white-space: nowrap;
            border-bottom: 1px solid $grey_200;
            border-collapse: collapse;
        }

        // tr:nth-child(odd) {
        //     background-color: $grey_75;
        // }

        th {
            color: $grey_400;
            font-weight: 500;
            font-size: 14px;
            background-color: $grey_75;
            padding: 15px 10px 15px 10px;
        }

        td {
            padding: 20px 10px 20px 10px;
            font-size: 13px;
        }
    }

    tr {
        cursor: pointer;

        &:hover {
            background-color: $grey_75;
        }
    }

    .status{
        padding: 3px 15px 3px 15px;
        border-radius: 20px;
        font-size: 12px;
        border-width: 1px;
        border-style: solid;
        width: fit-content;
    }

    .Rejected, .Cancelled{
        color: $error_500;
        background-color: $error_50;
        border-color: $error_100;
    }

    // .PendingApproval{
    //     color: #EB5017;
    //     background-color: #FFECE5;
    //     border-color: #FCB59A;
    // }

    .PendingPickup, .Approved {
        color: #0D5EBA;
        background-color: #E3EFFC;
        border-color: #B6D8FF;
    }

    .Delivered {
        color: #1f7556;
        background-color: #d6f3e9;
        border-color: #1f7556;
    }

    .PickedUp,  .GoingToPickup, .GoingToDeliver, .PendingApproval {
        color: #916c2e;
        background-color: #fcf0db;
        border-color: #916c2e;
    }

    .InTransit, .PendingDelivery {
        color: #640DBA;
        background-color: #EFE3FC;
        border-color: #DAB9FF;
    }

    .btn-redirect {
        border: 1.5px solid #0F794A;
        background-color: transparent;
        border-radius: 8px;
        padding: 4px 16px;
        font-size: 14px;
        font-weight: 700;
        color: #139C5F;
    }
}

.feature-btn {
    padding: 8px 12px;
    border-radius: 8px;
    border: 1px solid $grey_200;
    background-color: transparent;
    @include flexCenter;
    gap: 8px;
    font-size: 12px;
    font-weight: 500;
    color: $grey_600;
}

.top-filter-section {
    width: 50%;

    @include for-tablet-down {    
        width: 100%;
    }
}

.single-station-tabs {
    display: flex;
    border-bottom: 1px solid $grey_200;
    list-style: none;
    margin-bottom: 12px;
    width: 100%;
    padding: 0;
    justify-content: flex-start;
}

.station-tab {
    padding: 8px 14px;
    cursor: pointer;
    display: block;

    &-active {
        border-bottom: 2px solid $color_green;
        padding: 8px 24px;
        display: block;
        cursor: pointer;
    }
}