@import './variables.scss';

@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
}

@mixin for-tablet-down {
    @media (max-width: 1200px) { @content; }
}

@mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
}

@mixin thinBorder{
    border: 1px solid $grey_100;
}

@mixin mediumBorder{
    border: 1px solid $grey_200;
}

@mixin flexItemsCenter {
    display: flex;
    align-items: center;
}

@mixin flexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin sectionHeader {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 0;
}

@mixin sectionSub {
    font-size: 14px;
    color: $grey_500;
    margin-bottom: 0;
}