@import './variables.scss';
@import './mixin.scss';

.shipper-container{
    background-color: #FFF;
    border-radius: 20px;

    ul {
        list-style: none;
        padding: 0;

        li {
            padding: 12px 20px;
            border-bottom: 1px solid $grey_200;
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));

            .info-label {
                font-size: 13px;
                color: $grey_400;
            }
    
            .info-value {
                font-size: 13px;
                color: $grey_800;
            }

            .btn-redirect {
                border: 1.5px solid #0F794A;
                background-color: transparent;
                border-radius: 8px;
                padding: 4px 16px;
                font-size: 14px;
                font-weight: 700;
                color: #139C5F;
            }

            &:last-child {
                border: none;
            }

            p {
                margin: 0;
                color: $grey_400;
                font-size: 13px;
                // font-weight: 500;

            }

            div {
                &:last-child {
                    grid-column: span 2 / span 2;
                    p {
                        color: $grey_800;
                    }
                }
            }

        }
    }

    .redirect {
        color: $color_green !important;
        cursor: pointer;

        &:hover {
            color: $green_light !important;
        }
    }

    .title{
        color: #343a40;
    }

    .sender-name{
        color: #008561;
        cursor: pointer;

        &:hover{
            color: #45B39D;
        }
    }
}

.header{
    font-size: 18px;
    color: #343a40;
}

.shipment-timeline-container{
    margin-top: 20px;
    max-height: 470px;
    // min-height: 470px;
    // padding-right: 5px;
    overflow-y: scroll;

    &:hover{
        overflow-y: scroll;
    }
    &::-webkit-scrollbar {
        background-color: transparent;
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background-color: var(--custom-white);
    }
    &::-webkit-scrollbar-thumb {
        background-color: #D4E6F1 ;
        border-radius: 20px;
        border: 1px solid var(--custom-white);
    }
}

.order-item-row {
    padding: .5rem 0;
}
  
.order-item-grid {
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr));
}
  
.order-item-header {
    font-weight: 600;
}
  
.first-order {
    grid-column: span 2 / span 2;
}

.add-package-btn {
    background-color: #1f7556;
    border-radius: 100%;
    padding: 8px 14px;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
}

.shipment-section-wrap {
    background-color: #FFF;
    border-radius: 20px;
    padding: 24px 20px;
    margin-bottom: 24px;

    .sub-section {
        height: 100%;
        max-height: 300px;
        overflow-y: auto;
    }

    .status{
        padding: 8px 20px !important;
        border-radius: 24px;
        font-size: 14px;
        font-weight: 500;
        width: fit-content;
        height: fit-content;
        border-width: 1px;
        border-style: solid;
    }
    
    .Rejected, .Cancelled{
        color: #924040;
        background-color: #fde1e1;
        border-color: #fcd2d2;
    }
    
    // .PendingApproval{
    //     color: #464855;
    //     background-color: #e3e4e8;
    //     border-color: #879bd7;
    // }
    
    .PendingPickup, .InTransit {
        color: #37458b;
        background-color: #dee3fa;
        border-color: #ced5f8;
    }
    
    .Delivered{
        color: #1f7556;
        background-color: #d6f3e9;
        border-color: #91D6A8;
    }

    .Approved {
        color: #0D5EBA;
        background-color: #E3EFFC;
        border-color: #B6D8FF;
    }
    
    .PickedUp,  .GoingToPickup, .GoingToDeliver, .PendingDelivery, .PendingApproval{
        color: #916c2e;
        background-color: #fcf0db;
        border-color: #916c2e;
    }

    .status-contain {
        background-color: $grey_75;
        border: 1px solid $grey_200;
        border-radius: 20px;
        padding: 32px 24px;

        .status-icon {
            width: 40px;
            height: 40px;
            border-radius: 100%;
            background-color: #E8F7F0;
            border: 1.5px solid #B6E5D0;
            box-shadow: 0px 2.5px 5px -2.5px #B6E5D0, 0px 7.5px 10px -2.5px #68CDA040;
            margin-bottom: 12px;
            @include flexCenter;
        }

        .status-text {
            font-weight: 500;
            font-size: 14px;
            color: $grey_500;
            margin-bottom: 8px;

            &-bold {
                font-weight: 500;
                color: $grey_800;
                font-size: 16px;
                font-weight: 500;
                margin-left: 50px;

                &-two {
                    margin-left: 60px;
                }
            }

        }
    }

    .shipment-info {
        padding: 16px 20px;

        .info-label {
            font-size: 13px;
            font-weight: 500;
            color: $grey_400;
        }

        .info-value {
            font-size: 13px;
            color: $grey_800;
        }
    }

    .more-btn {
        width: 32px;
        height: 32px;
        @include flexCenter;
        border-radius: 8px;
        background-color: transparent;
        color: $color_green;
        border: 1px solid #0F794A;
    }
}

.section-wrap-secondary {
    padding: 24px 16px;
    background-color: $grey_75;
    border: 1px solid $grey_200;
    border-radius: 16px;

    .top-banner {
        @include flexItemsCenter;
        padding: 8px 12px;
        background-color: #FFF;
        gap: 8px;
        border-radius: 40px;
        width: fit-content;
        border: 1px solid $grey_200;
        margin-bottom: 16px;
    
        p {
          font-size: 12px;
          color: $grey-600;
        }
      }
}

@media (max-width: 400px) {
    .order-item-grid {
        width: 650px;
    }
}