$grey_50:#F9FAFB;
$color_white: #FFF;
$grey_75: #F7F9FC;
$grey_100: #F0F2F5;
$grey_200: #E4E7EC;
$grey_300: #D0D5DD;
$grey_400: #98A2B3;
$grey_500: #667185;
$grey_600: #475367;
$grey_700: #344054;
$grey_800: #1D2739;
$grey_900: #101928;
$green_light: #B6E5D0;
$color_green: #139C5F;
$success_50: #E7F6EC;
$success_400: #0F973D;
$error_50: #FBEAE9;
$error_100: #EB9B98;
$error_400: #D42620;
$error_500: #CB1A14;
$error_700: #9E0A05;
$secondary_50: #E3EFFC;
$secondary_400: #1671D9;
$secondary_600: #034592;